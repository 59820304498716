import React, { useState } from 'react'
import { Button } from '@windmill/react-ui'
import axios from "axios";
import Spinner from '../Buttons/Spinner';
import store from '../../store';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import billingSlice from '../../store/slices/billing';
import toast from 'react-hot-toast';






function BillingForm(props) {


    const BACKEND_URL = process.env.REACT_APP_SERVER_URL || window.env.REACT_APP_SERVER_URL;
    const setupUrl = `${BACKEND_URL}/v1/billing/subscription/setup`
    const finishUrl = `${BACKEND_URL}/v1/billing/subscription/complete`
    const infoUrl = `${BACKEND_URL}/v1/billing/info`
    const card = store.getState().billing.billing.card

    const [cardLoading, setCardLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

   
    const validateCard = () => {
        if (!stripe || !elements) {return;}
        const cardElement = elements.getElement(CardElement);
        cardElement.on('change', function(event) {
            if (event.complete) {
                setCardLoading(false);
            } else if (event.error) {
                setCardLoading(true);
            }

        });
    }

    const getBillingInfo = async () => {
        await axios.post(infoUrl)
            .then((r) => {
                store.dispatch(billingSlice.actions.setBilling(r.data));
            }).catch((e) => {
                console.log(e);
            });
    } 
  
    const addBillingMethod = async (event) => {
        
        event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {return;}
        const cardElement = elements.getElement(CardElement);

        // updating plan and adding a billing method
        if (props.type === 'initial') {

            await axios.post(setupUrl, props.setupData)
            .then((r) => {
                const client_secret = r.data.client_secret
                stripe.confirmCardPayment(client_secret, {
                    payment_method: {
                        card: cardElement,
                    }
                }).then((r) => {
                    var paymentMethod = r.paymentIntent.payment_method;
                    var finishData = {'payment_method': paymentMethod};
                    axios.post(finishUrl, finishData,)
                        .then((r) => {
                            getBillingInfo();
                            setLoading(false);
                            cardElement.clear();
                            setCardLoading(true);
                            props.callBack();
                        }).catch((err) => {
                            console.log(err);
                            setLoading(false);
                            toast.error('contact support')
                        });
                }).catch((e) => {
                    console.log(e);
                    setLoading(false);
                    toast.error('contact support')
                });
            })
            .catch((e) => {
                console.log(e);
                toast.error('contact support')
                setLoading(false);
            });


        } 
        
        // only updating billing method (card)
        else if (props.type === 'card') {

            stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            }).then((r) => {
                var paymentMethod = r.paymentMethod.id;
                var finishData = {'payment_method': paymentMethod};
                axios.post(finishUrl, finishData)
                .then((r) => {
                    getBillingInfo();
                    setLoading(false);
                    cardElement.clear();
                    setCardLoading(true);
                    toast.success('Card updated')
                    props.callBack()
                }).catch((err) => {
                    console.log(err)
                    setLoading(false);
                    toast.error('contact support')
                });
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                    toast.error('contact support')
                });
        }


    };
    
    
    
    return(

        <form onSubmit={addBillingMethod}>
            <div className='ring-1 ring-gray-700 dark:ring-gray-400 rounded-lg py-2 px-2'>
                <CardElement 
                    onChange={validateCard}
                    options={{
                    style: {
                        base: {
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': {
                            color: '#aab7c4',
                        },
                        },
                        invalid: {
                        color: '#9e2146',
                        },
                    },
                    }}
                />
            </div>
            <div className='flex justify-between mt-3'>
                <div className='my-auto'>
                    <span 
                        onClick={() => props.hideCardCallBack()} 
                        className={`hover:text-blue-600 text-blue-500 my-4 text-xs cursor-pointer ${card ? 'visible' : 'invisible'}`}
                    >
                        Hide Card
                    </span>
                </div>
                <Button layout="outline" size="small" type="submit" disabled={cardLoading || loading}>
                    <Spinner display={loading}/>
                    Update
                </Button>
            </div>
        </form>
        
    )
}



export default BillingForm