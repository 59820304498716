import { useState, useEffect } from 'react'
import routes from '../../routes/sidebar'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as Icons from '../../icons'
import { ChevronLeftIcon } from '@heroicons/react/outline'
import SidebarSubmenu from './SidebarSubmenu'
import SiteModal from '../Modals/SiteModal'
import { Badge, } from '@windmill/react-ui'
import store from '../../store'
import Logo from '../../assets/img/logo_small.png'



function Icon({ icon, ...props }) {
  const Icon = Icons[icon]
  return <Icon {...props} />
}



function SidebarContent() {


  // connecting metrics to redux store
  const metrics = useSelector(state => state.metrics.metrics);
  const [open, setOpen] = useState(window.localStorage.sidebar === 'closed' ? false : true)
  const [hovering, setHovering] = useState(false)

  const toggleSidebar = () => {
    if (open) {
      window.localStorage.setItem('sidebar', 'closed');
      window.dispatchEvent(new Event("storage"));
      setOpen(false)
    }else{
      window.localStorage.setItem('sidebar', 'open');
      window.dispatchEvent(new Event("storage"));
      setOpen(true)
    }
  }


  const checkSidebar = () => {
    let sidebar = window.localStorage.getItem('sidebar')
    setOpen(sidebar === 'open')
  }

  useEffect(() => {
    window.addEventListener('storage', checkSidebar)

    return () => {
      window.removeEventListener('storage', checkSidebar)
    }
  })


  return (
    <div 
      className={`py-4 text-gray-500 dark:text-gray-400 h-full relative ease-in-out duration-300 ${open ? 'w-64' : 'w-24' }`}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <a className="" href="/">
        <div className='flex justify-start mt-1 ml-6'>
          <img src={Logo} className={`w-6 h-6 ${open ? '' : 'ml-3'}`}/>
          {open && <h1 className='ml-2 text-xl font-bold text-gray-800 dark:text-gray-200'>Cursion</h1>}
        </div>
      </a>
      <ul className="mt-6">
        {routes.map((route) =>
          route.routes ? (
            <SidebarSubmenu route={route} key={route.name} />
          ) : (
            <li className="relative" key={route.name}>
              <NavLink
                to={route.path}
                className={({ isActive }) =>
                  [
                    isActive ? 
                      "inline-flex items-center w-full text-sm font-semibold text-gray-800 dark:text-gray-100 border-l-[.25rem] rounded-tr-lg rounded-br-lg border-blue-600 pl-6" 
                        : 
                      "inline-flex items-center w-full text-sm font-semibold border-l-[.25rem] rounded-tr-lg rounded-br-lg border-transparent pl-6",
                  ].join(" ")
                }
              >
                {open &&
                  <div className='my-2 p-2 inline-flex items-center rounded-md hover:bg-gray-100 dark:hover:bg-gray-900 hover:text-gray-800 dark:hover:text-gray-200 w-3/4' alt={route.name} aria-label={route.name}>
                    <div className=''><Icon className="w-5 h-5" aria-hidden="true" icon={route.icon} /></div>
                    <div className=''><span className="ml-4">{route.name}</span></div>
                    {route.badge && <div className='ml-4'><Badge type={route.badge}>{route.badgeText}</Badge></div>}
                    {route.name === 'Issues' && <div className='ml-4'><Badge type='warning'>{(metrics !== undefined && metrics !== null && metrics.open_issues > 0) && metrics.open_issues}</Badge></div>}
                  </div>
                }
                {!open && 
                  <div className='my-2 p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-900 hover:text-gray-800 dark:hover:text-gray-200' aria-label={route.name}>
                    <Icon className="w-5 h-5" aria-hidden="false" alt={route.name} icon={route.icon}/>
                  </div>
                }
              </NavLink>
            </li>
          )
        )}
      </ul>
      <div className="px-6 my-6" >
        <SiteModal open={open}/>
      </div>
      <div className="bottom-2 absolute border-t-2 border-gray-100 dark:border-gray-900 w-full">
        <div className='px-6 my-3'>
          <h4 className='text-[.9rem] font-semibold truncate w-[90%]'>{store.getState().team.team.name}</h4>
          <h5 className='text-[.7rem] truncate w-[90%]'>{store.getState().auth.account.email}</h5>
        </div>
      </div>
      {hovering && 
        <div 
          className={`hidden lg:flex p-1 fixed top-[42%] rounded-full ease-in-out duration-300 bg-gray-200 dark:bg-gray-700 cursor-pointer ${open ? 'left-[15rem]' : 'rotate-180 left-[5rem]'}`} 
          onClick={toggleSidebar}
        >
          <ChevronLeftIcon className='h-6 w-6 dark:text-gray-400 text-gray-500'/>
        </div>
      }
    </div>
  )
}

export default SidebarContent
