



const Modal = (props) => {


    return (

        <div className={`${props.isOpen ? '' : 'hidden'} fixed inset-0 z-[100] flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center`}
            onClick={() => props.onClose()}
        >
            <div 
                id={props.id} 
                className={`${props.isOpen ? '' : 'hidden'} w-full overflow-hidden sm:m-4 sm:max-w-xl`}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="relative p-4 w-full max-w-2xl max-h-full text-wrap">
                    {/* <!-- Modal content --> */}
                    <div className="relative bg-gray-50 rounded-lg shadow dark:bg-gray-800">
                        <div className="flex items-center justify-between p-4 md:p-5 rounded-t dark:border-gray-600">
                            <h3 className="text-lg font-semibold text-gray-700 dark:text-white">
                                {props.title}
                            </h3>
                            <button type="button" onClick={() => props.onClose()} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-700 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <svg className="w-3 h-3"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="leading-normal text-gray-800 dark:text-gray-400 text-[.95rem]">
                            {props.children}
                        </div>
                        

                    </div>
                </div>
            </div>
        </div>
        


    )
}


export default Modal