import React, { useState } from 'react';
import { Input, Textarea, Button, Label } from '@windmill/react-ui';
import siteDataSlice from "../../store/slices/siteData";
import { PlusIcon } from '@heroicons/react/solid';
import axios from "axios";
import store from '../../store';
import toast from 'react-hot-toast';
import Spinner from '../Buttons/Spinner'
import Modal from './Modal/Modal'
import ModalBody from './Modal/ModalBody'
import ModalFooter from './Modal/ModalFooter'




function SiteModal(props) {
  const BACKEND_URL = process.env.REACT_APP_SERVER_URL || window.env.REACT_APP_SERVER_URL;
  const MODE = process.env.REACT_APP_MODE || window.env.REACT_APP_MODE
  const plan = store.getState().billing.billing.plan.name || null
  const url = BACKEND_URL + '/v1/ops/site'
  const sites_url = BACKEND_URL + '/v1/ops/site'

  const [site_url, setSiteUrl] = useState('');
  const [page_urls, setPageUrls] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false)


  function openModal() {
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false)
  }

  const handleKeyDown = (evt) => {
    if (evt.key === 'Enter') {
      handleAddSite(evt)
    }
  }

  const handleAddSite = () => {

    if (MODE !== 'selfhost' && plan === 'selfhost') {
      toast.error('not allowed')
    }

    // cleaning page_urls if present
    var _page_urls = null
    if (page_urls.length > 0){
      _page_urls = []
      if (page_urls.includes(',')){
        var temp_urls = page_urls.split(',')
      }else{
        var temp_urls = page_urls.split('\n')
      }
      for (var i in temp_urls) {
        var _url = temp_urls[i].trim()
        _page_urls.push(_url)
      }
    }
    
    const data = {
      "site_url": site_url,
      "page_urls": _page_urls
    }

    setIsLoading(true);
    if(site_url === ''){
      setIsLoading(false);
      toast.error('enter a site url');
    } else if (!site_url.startsWith('https://') && !site_url.startsWith('http://')){
      setIsLoading(false);
      toast.error('must begin with http');
    }else{
      axios.post(`${url}`, data)
        .then((res) => {
          if (res.data) {
            axios.get(`${sites_url}`)
              .then((r) => {
                store.dispatch(siteDataSlice.actions.setSiteData(r.data));
                setIsLoading(false);
                setModalOpen(false);
                setSiteUrl('');
                toast.success('Site added');
              }).catch((e) => {
                if (e.response) {
                  toast.error(e.response.data.reason)
                  setIsLoading(false);
                }
              })
          }
        }).catch((e) => {
          if (e.response) {
            toast.error(e.response.data.reason)
            setIsLoading(false);
          }
        })
    }

    
  }



  return (
    <>

      <div>
        <button 
          className={`bg-blue-600 hover:bg-blue-700 rounded-lg text-white flex justify-between ${props.open ? 'px-4 py-2' : 'p-2 ml-1'}`}
          onClick={openModal}
        >
          {props.open &&
            <div>
              <span className="mr-2 text-[.8rem] font-semibold">Add Site</span>
            </div>
          } 
          <div className='my-auto'>
            <PlusIcon className={`text-white ${props.open ? 'h-4 w-4' : 'h-5 w-5'}`}/>
          </div>
        </button>
      </div>
      <Modal isOpen={modalOpen} onClose={closeModal} title={'Add Site'}>
        <ModalBody>
          <div>
            <Input 
              onKeyDown={handleKeyDown}
              className="rounded-md p-2 mt-1" 
              placeholder='https://example.com'
              value={site_url}
              onChange={e => setSiteUrl(e.target.value)}
              autoFocus={true}
            />
          </div>
          
          <div>
          <div className='mt-8'>
            <span
              className='text-sm underline text-blue-600 cursor-pointer w-auto'
              onClick={() => setShowAdvanced(!showAdvanced)}
            >
              {showAdvanced ? 'Hide' : 'Show'} Advanced
            </span>
          </div>
            {showAdvanced &&
              <div className='mt-4'>
                <Label className="text-sm font-semibold">Add Pages</Label>
                <Textarea className="rounded-md p-2 mt-1" rows="3" placeholder='https://example.com/blog&#10;https://example.com/contact' 
                  name="page_urls"
                  onChange={e => setPageUrls(e.target.value)}/>
              </div>
            }
          </div>
          
        </ModalBody>
        <ModalFooter>

          <div className="hidden sm:block">
            <Button layout="outline" onClick={handleAddSite} type="submit" disabled={isLoading || (MODE !== 'selfhost' && plan === 'selfhost')}>
            <Spinner display={isLoading}/>
              Add Site
            </Button>
          </div>
          <div className="block w-full sm:hidden">
            <Button block size="large" layout="outline" onClick={handleAddSite} type="submit" disabled={isLoading || (MODE !== 'selfhost' && plan === 'selfhost')}>
            <Spinner display={isLoading}/>
              Add Site
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default SiteModal


