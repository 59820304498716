export const countryCodes = [
    {
        "country": "United States",
        "code": "+1",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg"
    },
    {
        "country": "Canada",
        "code": "+1",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/CA.svg"
    },
    {
        "country": "United Kingdom",
        "code": "+44",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg"
    },
    {
        "country": "Germany",
        "code": "+49",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/DE.svg"
    },
    {
        "country": "Australia",
        "code": "+61",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/AU.svg"
    },
    {
        "country": "India",
        "code": "+91",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/IN.svg"
    },
    {
        "country": "Japan",
        "code": "+81",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/JP.svg"
    },
    {
        "country": "Brazil",
        "code": "+55",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/BR.svg"
    },
    {
        "country": "Mexico",
        "code": "+52",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/MX.svg"
    },
    {
        "country": "France",
        "code": "+33",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg"
    },
    {
        "country": "Italy",
        "code": "+39",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/IT.svg"
    },
    {
        "country": "South Korea",
        "code": "+82",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/KR.svg"
    },
    {
        "country": "Spain",
        "code": "+34",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/ES.svg"
    },
    {
        "country": "Netherlands",
        "code": "+31",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/NL.svg"
    },
    {
        "country": "Turkey",
        "code": "+90",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/TR.svg"
    },
    {
        "country": "Saudi Arabia",
        "code": "+966",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/SA.svg"
    },
    {
        "country": "South Africa",
        "code": "+27",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/ZA.svg"
    },
    {
        "country": "United Arab Emirates",
        "code": "+971",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/AE.svg"
    },
    {
        "country": "Sweden",
        "code": "+46",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/SE.svg"
    },
    {
        "country": "Switzerland",
        "code": "+41",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/CH.svg"
    },
    {
        "country": "Norway",
        "code": "+47",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/NO.svg"
    },
    {
        "country": "Denmark",
        "code": "+45",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/DK.svg"
    },
    {
        "country": "Belgium",
        "code": "+32",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/BE.svg"
    },
    {
        "country": "Austria",
        "code": "+43",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/AT.svg"
    },
    {
        "country": "Poland",
        "code": "+48",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/PL.svg"
    },
    {
        "country": "Argentina",
        "code": "+54",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/AR.svg"
    },
    {
        "country": "Malaysia",
        "code": "+60",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/MY.svg"
    },
    {
        "country": "Thailand",
        "code": "+66",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/TH.svg"
    },
    {
        "country": "Philippines",
        "code": "+63",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/PH.svg"
    },
    {
        "country": "Indonesia",
        "code": "+62",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/ID.svg"
    },
    {
        "country": "Vietnam",
        "code": "+84",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/VN.svg"
    },
    {
        "country": "New Zealand",
        "code": "+64",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/NZ.svg"
    },
    {
        "country": "Israel",
        "code": "+972",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/IL.svg"
    },
    {
        "country": "Pakistan",
        "code": "+92",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/PK.svg"
    },
    {
        "country": "Nigeria",
        "code": "+234",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/NG.svg"
    },
    {
        "country": "Ukraine",
        "code": "+380",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/UA.svg"
    },
    {
        "country": "Egypt",
        "code": "+20",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/EG.svg"
    },
    {
        "country": "Bangladesh",
        "code": "+880",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/BD.svg"
    },
    {
        "country": "Chile",
        "code": "+56",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/CL.svg"
    },
    {
        "country": "Ireland",
        "code": "+353",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/IE.svg"
    },
    {
        "country": "Singapore",
        "code": "+65",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/SG.svg"
    },
    {
        "country": "Hong Kong",
        "code": "+852",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/HK.svg"
    },
    {
        "country": "Portugal",
        "code": "+351",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/PT.svg"
    },
    {
        "country": "Greece",
        "code": "+30",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/GR.svg"
    },
    {
        "country": "Colombia",
        "code": "+57",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/CO.svg"
    },
    {
        "country": "Morocco",
        "code": "+212",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/MA.svg"
    },
    {
        "country": "Venezuela",
        "code": "+58",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/VE.svg"
    },
    {
        "country": "Czech Republic",
        "code": "+420",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/CZ.svg"
    }
]


