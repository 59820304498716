import { lazy } from 'react'

const Page404 = lazy(() => import('../pages/404'))
const InternalApi = lazy(() => import('../pages/InternalApi'))
const Billing = lazy(() => import('../pages/Billing'))
const BillingUpdate = lazy(() => import('../pages/BillingUpdate'))
const SiteDetail = lazy(() => import('../pages/SiteDetail'))
const PageDetail = lazy(() => import('../pages/PageDetail'))
const ScanDetail = lazy(() => import('../pages/ScanDetail'))
const TestDetail = lazy(() => import('../pages/TestDetail'))
const SlackConfirm = lazy(() => import('../store/auth/SlackConfirm'))
const Home = lazy(() => import('../pages/Home'))
const Account = lazy(() => import('../pages/Account'))
const Logs = lazy(() => import('../pages/Logs'))
const Report = lazy(() => import('../pages/Report'))
const Migration = lazy(() => import('../pages/Migration'))
const CaseRunDetail = lazy(() => import('../pages/CaseRunDetail'))
const Cases = lazy(() => import('../pages/Cases'))
const CaseDetail = lazy(() => import('../pages/CaseDetail'))
const Issues = lazy(() => import('../pages/Issues'))
const IssueDetail = lazy(() => import('../pages/IssueDetail'))
const IssueCreate = lazy(() => import('../pages/IssueCreate'))
const Schedules = lazy(() => import('../pages/Schedules'))
const Flows = lazy(() => import('../pages/Flows'))
const FlowDetail = lazy(() => import('../pages/FlowDetail'))
const FlowRunDetail = lazy(() => import('../pages/FlowRunDetail'))




/**
 * ⚠ These are internal routes!
 * They will be rendered inside the app, using the default `containers/Layout`.
 * If you want to add a route to, let's say, a landing page, you should add
 * it to the `App`'s router, exactly like `Login`, `CreateAccount` and other pages
 * are routed.
 *
 * If you're looking for the links rendered in the SidebarContent, go to
 * `routes/sidebar.js`
 */
const routes = [
  {
    path: '',
    component: <Home/>, 
  },
  {
    path: 'internal-api',
    component: <InternalApi/>,
  },
  {
    path: 'logs',
    component: <Logs/>,
  },
  {
    path: 'account',
    component: <Account/>,
  },
  {
    path: 'billing',
    component: <Billing/>, 
  },
  {
    path: 'billing/update', 
    component: <BillingUpdate/>,
  },
  {
    path: 'schedule',
    component: <Schedules/>, 
  },
  {
    path: '404',
    component: <Page404/>,
  },
  {
    path: 'site/:id',
    component: <SiteDetail/>,
  },
  {
    path: 'page/:id',
    component: <PageDetail/>,
  },
  {
    path: 'page/:id/report',
    component: <Report/>, 
  },
  {
    path: 'site/:id/migration',
    component: <Migration/>, 
  },
  {
    path: 'scan/:id',
    component: <ScanDetail/>,
  },
  {
    path: 'test/:id',
    component: <TestDetail/>,
  },
  {
    path: 'case',
    component: <Cases/>,
  },
  {
    path: 'case/:id',
    component: <CaseDetail/>,
  },
  {
    path: 'caserun/:id',
    component: <CaseRunDetail/>,
  },
  {
    path: 'flow',
    component: <Flows/>,
  },
  {
    path: 'flow/:id',
    component: <FlowDetail/>,
  },
  {
    path: 'flowrun/:id',
    component: <FlowRunDetail/>,
  },
  {
    path: 'issue',
    component: <Issues/>,
  },
  {
    path: 'issue/:id',
    component: <IssueDetail/>,
  },
  {
    path: 'issue/new',
    component: <IssueCreate/>,
  },
  {
    path: 'slack',
    component: <SlackConfirm/>,
  },
]

export default routes
