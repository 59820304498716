import { GoogleIcon } from "../../icons"



const GoogleButton = (props) => {


    return (
        <>
            <button 
                className={`align-bottom items-center leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm w-full flex justify-center ${props.disabled ? 'cursor-not-allowed bg-gray-400 hover:bg-gray-400 active:bg-gray-400 text-gray-600' : 'cursor-pointer bg-gray-200 hover:bg-gray-100 active:bg-gray-100 shadow text-black' }`} 
                disabled={props.disabled}
                onClick={() => props.callBack()}
            >
                <GoogleIcon className='h-5'/>
                <span className="ml-2">Continue with Google</span>
            </button>
        </>
    )
}


export default GoogleButton