import React, { useContext } from 'react'
import SidebarContent from './SidebarContent'
import { Backdrop } from '@windmill/react-ui'
import { SidebarContext } from '../../context/SidebarContext'







function MobileSidebar() {
  
  const { isSidebarOpen, closeSidebar } = useContext(SidebarContext)

  return (
    <>
      {isSidebarOpen && <Backdrop onClick={closeSidebar} /> }
      <aside className={`${!isSidebarOpen ? 'hidden' : ''} fixed inset-y-0 z-50 flex-shrink-0 w-64 mt-16 overflow-y-auto bg-white dark:bg-gray-800 lg:hidden`}>
        <SidebarContent />
      </aside>
    </>
    

  )
}

export default MobileSidebar
