import React , { useState, useCallback } from 'react'
import GoogleButton from '../components/Buttons/GoogleButton';
import { Link } from 'react-router-dom'
import { useDispatch } from "react-redux";
import authSlice from "../store/slices/auth";
import axios from "axios";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import Spinner from '../components/Buttons/Spinner';
import Logo from '../assets/img/logo_small.png'
import { Input, Label, Button } from '@windmill/react-ui'






function Register() {

  
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [agree, setAgree] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const next = localStorage.getItem('next')

    const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const redirectUri = 'v1/auth/google';
    const BACKEND_URL = process.env.REACT_APP_SERVER_URL || window.env.REACT_APP_SERVER_URL;
    const REACT_APP_GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || window.env.REACT_APP_GOOGLE_CLIENT_ID;

    const handleLogin = (email, password, first_name, last_name) => {

      var data = { 
        'username': email, 
        'email': email, 
        'password': password,
        'first_name': first_name, 
        'last_name': last_name, 
      }

      axios.post(`${BACKEND_URL}/v1/auth/register/`, data, { skipAuthRefresh: true }
      )
      .then((res) => {
        dispatch(
          authSlice.actions.setAuthTokens({
            token: res.data.token,
            refreshToken: res.data.refresh,
            api_token: res.data.api_token,
          })
        );
        dispatch(authSlice.actions.setAccount(res.data.user));
        setIsLoading(false);
        
        // push new user to Team page
        if (next){
          window.location.href = next
          localStorage.removeItem('next')
        } else{
          navigate("/account/new");
        }
      })
      .catch((err) => {
        if (err.response.data.username) {
            setMessage(err.response.data.username.toString());
        } else if (err.response.data.password) {
          setMessage(err.response.data.password.toString());
        } else {
          setMessage(err.response.data.detail.toString());
        }
        setIsLoading(false);
      });
    };

    const formik = useFormik({
        initialValues: {
          email: "",
          password: "",
          first_name: "",
          last_name: "",
        },
        onSubmit: (values) => {
          setIsLoading(true);
          handleLogin(
            values.email, 
            values.password,
            values.first_name,
            values.last_name,
          );
        },
        validationSchema: Yup.object({
          email: Yup.string().trim().required("Email is required"),
          password: Yup.string().trim().required("Password is required"),
          first_name: Yup.string().trim().required("First Name is required"),
          last_name: Yup.string().trim().required("Last Name is required"),
        }),
    });


    const openGoogleLoginPage = useCallback(() => {
  
      const scope = [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile'
      ].join(' ');
  
      const params = {
        response_type: 'code',
        client_id: REACT_APP_GOOGLE_CLIENT_ID,
        redirect_uri: `${BACKEND_URL}/${redirectUri}`,
        prompt: 'select_account',
        access_type: 'offline',
        scope
      };
  
      const urlParams = new URLSearchParams(params).toString();
  
      window.location = `${googleAuthUrl}?${urlParams}`;
    }, [BACKEND_URL, REACT_APP_GOOGLE_CLIENT_ID]);

  return (
    <div className='min-h-screen p-6 bg-gray-100 dark:bg-gray-900'>
      
      <div className='flex justify-center mx-auto my-10'>
        <img
          aria-hidden="true"
          className="w-12 h-12"
          src={Logo}
          alt="Cursion Logo"
        />
        <h1 className='mt-1 font-bold text-4xl ml-3 text-gray-600 dark:text-gray-400'>Cursion</h1>
      </div>

      <div className="flex items-center">
        <div className="flex-1 h-full max-w-md mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
          <div className="flex flex-col overflow-y-auto">
            <main className="flex items-center justify-center p-6 sm:p-12">
              <div className="w-full">
                <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                  Create account
                </h1>
                
                <form onSubmit={formik.handleSubmit}>
                  <div className='flex justify-between mb-4 w-full'>
                    <Label className='mr-2'>
                      <Input className="rounded-md p-2 mt-1 w-full flex" 
                        type="name" 
                        placeholder="Jane"
                        autoComplete="given-name"
                        name="first_name"
                        value={formik.values.first_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.first_name ? (
                          <div>{formik.errors.first_name} </div>
                        ) : null}
                    </Label>
                    <Label className='ml-2'>
                      <Input className="rounded-md p-2 mt-1 w-full flex" 
                        type="name" 
                        autoComplete="family-name"
                        placeholder="Doe"
                        name="last_name"
                        value={formik.values.last_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.last_name ? (
                          <div>{formik.errors.last_name} </div>
                        ) : null}
                    </Label>
                  </div>

                  <Label>
                    <Input className="rounded-md p-2 mt-1" 
                      type="email" 
                      placeholder="jane@example.com"
                      autoComplete="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.email ? (
                        <div>{formik.errors.email} </div>
                      ) : null}
                  </Label>

                  <Label className="mt-4">
                    <Input className="rounded-md p-2 mt-1" 
                      type="password" 
                      autoComplete="password"
                      placeholder="••••••••••"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.password ? (
                        <div>{formik.errors.password} </div>
                      ) : null}
                  </Label>

                  <div className="mb-4 text-sm font-semibold text-red-600" hidden={false}>
                    <br></br>
                    {message}
                  </div>

                  <Label className="mt-0" check>
                    <Input 
                      type="checkbox" 
                      checked={agree}
                      onChange={() => setAgree(!agree)}
                      className='rounded-sm'
                    />
                    <span className="ml-2">
                      I agree to the <a href="https://cursion.dev/terms" target='_blank' className="underline decoration-blue-600">terms</a> & <a href="https://cursion.dev/privacy" target='_blank' className="underline decoration-blue-600">privacy policy</a>
                    </span>
                  </Label>

                  <Button className="mt-4 bg-blue-600 hover:bg-blue-700 w-full flex justify-center"  
                    type="submit"
                    disabled={isLoading ? true : !agree}
                  >
                    <Spinner display={isLoading}/>
                    <p>Register</p>
                  </Button>
                
                </form>
                <hr className="my-8" />
                <div className='w-full mx-auto'>
                  <GoogleButton
                    callBack={openGoogleLoginPage}
                    disabled={(!REACT_APP_GOOGLE_CLIENT_ID || REACT_APP_GOOGLE_CLIENT_ID === 'null') ? true : !agree}
                  />
                </div>
                <p className="mt-4">
                  <Link
                    className="text-sm font-medium text-blue-600 dark:text-blue-400 hover:underline"
                    to="/login"
                  >
                    Already have an account? Login
                  </Link>
                </p>
              </div>
            </main>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Register
