import { useContext, useState, useEffect } from 'react'
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import authSlice from "../store/slices/auth";
import billingSlice from "../store/slices/billing";
import siteDataSlice from "../store/slices/siteData";
import teamSlice from '../store/slices/team';
import memberSlice from '../store/slices/member';
import metricsSlice from '../store/slices/metrics';
import { SidebarContext } from '../context/SidebarContext'
import SearchForm from './Forms/SearchForm';
import {
  MoonIcon,
  SunIcon,
  MenuIcon,
  OutlinePersonIcon,
  OutlineLogoutIcon,
  LifeRingIcon,
  UserCircleIcon,
} from '../icons'
import Dropdown from './Buttons/Dropdown/Dropdown';
import DropdownItem from './Buttons/Dropdown/DropdownItem';
import { ChatIcon } from '@heroicons/react/outline';




function Header(props) {

  const [theme, setTheme] = useState(localStorage.theme);
  const colorTheme = theme === "dark" ? "light" : "dark";
  const { toggleSidebar } = useContext(SidebarContext)

  // const [isNotificationsMenuOpen, setIsNotificationsMenuOpen] = useState(false)
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false)

  // function handleNotificationsClick() {
  //   setIsNotificationsMenuOpen(!isNotificationsMenuOpen)
  // }

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove(colorTheme);
    root.classList.add(theme);
    localStorage.setItem('theme', theme);
  }, [theme, colorTheme]);


  function toggleMode (){
    console.log(theme)
    if (theme === 'light'){
      localStorage.setItem('theme', 'dark');
      root.classList.remove(theme);
      root.classList.add('dark');
      setTheme('dark')
    }
    if (theme === 'dark'){
      localStorage.setItem('theme', 'light');
      root.classList.remove(theme);
      root.classList.add('light');
      setTheme('light')
    }
  }

  function handleProfileClick() {
    setIsProfileMenuOpen(!isProfileMenuOpen)
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(authSlice.actions.setLogout());
    dispatch(billingSlice.actions.setLogout());
    dispatch(siteDataSlice.actions.setLogout());
    dispatch(siteDataSlice.actions.setLogout());
    dispatch(teamSlice.actions.setLogout());
    dispatch(memberSlice.actions.setLogout());
    dispatch(metricsSlice.actions.setLogout());
    navigate("/login");
  };

  const handleAccount = () => {
    setIsProfileMenuOpen(false);
    navigate("/account");
  };

  const navToDocs = () =>{
    window.open("https://docs.cursion.dev", "_blank");
  }

  const navToSupport = () =>{
    window.open("https://join.slack.com/t/cursion/shared_invite/zt-2ecly7yps-6dFqI2hfJ062ESGjGrwpxw", "_blank");
  }

  return (
    <header className="py-4 bg-white dark:bg-gray-800">
      <div className="flex justify-between h-full px-6 mx-auto text-blue-600 dark:text-blue-300">
        <div className="flex items-start justify-start">
          {/* <!-- Mobile hamburger --> */}
          <button
            className="p-1 mr-5 -ml-1 justify-items-start rounded-md lg:hidden focus:outline-none focus:shadow-outline-blue"
            onClick={toggleSidebar}
            aria-label="Menu"
          >
            <MenuIcon className="w-6 h-6" aria-hidden="true" />
          </button>
        </div>

        {/* search component */}
        <div className='max-w-xl w-3/4'>
          <SearchForm focus={props.search}/>
        </div>

        <div className="flex items-end justify-end my-auto">
          <ul className="flex flex-shrink-0 md:space-x-6 space-x-3">
            {/* <!-- Theme toggler --> */}
            <li className="relative">
                {theme === 'dark' ? (
                  <MoonIcon onClick={toggleMode} className="w-5 h-5  cursor-pointer " aria-hidden="true" />
                ) : (
                  <SunIcon onClick={toggleMode} className="w-5 h-5  cursor-pointer " aria-hidden="true" />
                )}
            </li>
            {/* <!-- Profile menu --> */}
            <li className="relative">

                <UserCircleIcon 
                  className='text-blue-600 cursor-pointer -my-2' 
                  style={{ width: '2.2rem', height: '2.2rem' }}
                  onClick={handleProfileClick} 
                />

              <Dropdown
                classes={'-ml-48 mt-2'}
                isOpen={isProfileMenuOpen}
                onClose={() => setIsProfileMenuOpen(false)}   
              >
                  <DropdownItem 
                    className='flex items-center cursor-pointer w-full px-2 py-1 text-sm font-medium transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200'
                    onClick={handleAccount}
                  >
                    <OutlinePersonIcon className="w-4 h-4 mr-3 my-auto" aria-hidden="true" />
                    <span>Account</span>
                  </DropdownItem>
                  <DropdownItem 
                    className='flex items-center cursor-pointer w-full px-2 py-1 text-sm font-medium transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200'
                    onClick={navToSupport}
                  >
                    <ChatIcon className="w-4 h-4 mr-3 my-auto" aria-hidden="true" />
                    <span>Support</span>
                  </DropdownItem>
                  <DropdownItem 
                    className='flex items-center cursor-pointer w-full px-2 py-1 text-sm font-medium transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200'
                    onClick={navToDocs}
                  >
                    <LifeRingIcon className="w-4 h-4 mr-3 my-auto" aria-hidden="true" />
                    <span>Docs</span>
                  </DropdownItem>
                  <DropdownItem 
                    className='flex items-center cursor-pointer w-full px-2 py-1 text-sm font-medium transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200'
                    onClick={handleLogout}
                  >
                    <OutlineLogoutIcon className="w-4 h-4 mr-3 my-auto" aria-hidden="true" />
                    <span>Log out</span>
                  </DropdownItem>
              </Dropdown>

            </li>
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header
