import { useEffect, useState } from 'react'
import { countryCodes } from '../Data/CountryCodes'






const PhoneNumberForm = (props) => {

    
    const [open, setOpen] = useState(false)
    const [number, setNumber] = useState('')
    const [country, setCountry] = useState(null)
    

    const setup = () => {
        if (props.number){
            for (let i in countryCodes){
                if(props.number.startsWith(countryCodes[i].code)){
                    setCountry(countryCodes[i])
                    let num = props.number.replace(countryCodes[i].code, '')
                    setNumber(num)
                    break
                }
            }
        }else{
            setCountry(countryCodes[0])
        }
    }


    useEffect(() => {
        setup()
    },[])


    // Format Phone Number
    useEffect(() => {
        formatPhoneNumber(number)
    }, [number])

    const formatPhoneNumber = (number) => {
        let cleaned = ('' + number).replace(/\D/g, '');
        let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            let final = ['(', match[2], ') ', match[3], '-', match[4]].join('');
            setNumber(final)
            return 
        }
        return null;
    }


    useEffect(() => {
        if (country){
            let final = `${country.code} ${number}`
            props.callBack(final)
        }
    }, [number, country])


    if (!country){return null}

    return (

        <div className='flex justify-between relative'>
            <button 
                onClick={() => setOpen(!open)}
                className='w-24 z-10 flex justify-center py-2 text-sm text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-md hover:bg-gray-200 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-gray-300 dark:border-gray-600'
            >
                <img src={country.flag} className='mr-1 w-4 h-3 my-auto'/>
                <span className='mr-1'>{country.code}</span>
            </button>
            {open &&
                <div className='absolute left-0 top-12 z-10 bg-white divide-y divide-gray-100 rounded-md shadow w-[17rem] dark:bg-gray-700 max-h-32 overflow-auto border-[1px] dark:border-gray-600 border-gray-100'>
                    <ul className='py-2 text-sm text-gray-700 dark:text-gray-300' aria-labelledby='dropdown-phone-button'>
                        {countryCodes.map((c, i) => (
                            <li key={i}>
                                <div 
                                    type='button' 
                                    className='cursor-pointer inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white' role='menuitem'
                                    onClick={() => {
                                        setCountry(c)
                                        setOpen(false)
                                    }}
                                >
                                    <span className='inline-flex items-center'>
                                        <img src={c.flag} className='w-4 h-3 mr-2'/>
                                        {c.country} ({c.code})
                                    </span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            }

            <input 
                className='p-2 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-md border-s-0 border border-gray-300  dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-300 focus:border-r-blue-500 focus:border-x-blue-500' 
                type='tel'
                id='phone-input'
                pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}' 
                placeholder='123-456-7890' 
                required 
                value={number}
                onChange={(e) => setNumber(e.target.value)}
            />

        </div>

    )
}



export default PhoneNumberForm