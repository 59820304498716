const Toggle = (props) => {

    return (
        <div className="relative">
            <label className="inline-flex items-center cursor-pointer">
                <input type="checkbox" value={props.checked} className="sr-only peer" onChange={() => props.onClick()}/> 
                <div className={`relative w-10 h-5 ${props.checked ? 'bg-blue-600' : 'bg-gray-300 dark:bg-gray-700'} rounded-full shadow-inner transition-colors duration-200 ease-in-out`}>
                    <div className={`absolute top-[.13rem] left-[.15rem] w-4 h-4 bg-white dark:bg-gray-300 rounded-full shadow transition-transform duration-200 ease-in-out ${props.checked ? 'translate-x-[1.2rem]' : ''}`}></div>
                </div>
            </label>
        </div>
    )
}


export default Toggle