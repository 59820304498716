import {useState, useEffect} from 'react'

function Main({ children }) {

  const [sidebar, setSidebar] = useState(window.localStorage.sidebar)

  const checkSidebar = () => {
    setSidebar(window.localStorage.getItem('sidebar'))
  }

  useEffect(() => {
    window.addEventListener('storage', checkSidebar)
    return () => {
      window.removeEventListener('storage', checkSidebar)
    }
  })


  return (
    <main className="h-full overflow-y-auto overscroll-none">
      <div className={`grid mx-auto ${sidebar === 'open' ? 'px-6' : 'px-7'}`}>{children}</div>
    </main>
  )
}

export default Main
