import React , { useEffect } from 'react'
import authSlice from "../store/slices/auth";
import billingSlice from '../store/slices/billing';
import memberSlice from '../store/slices/member';
import teamSlice from '../store/slices/team';
import store from '../store';
import Loader from '../components/Buttons/Loader';
import axios from 'axios';



function GoogleConfirm() {

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const next = localStorage.getItem('next')
  const wait = ms => new Promise(res => setTimeout(res, ms));

  const BACKEND_URL = process.env.REACT_APP_SERVER_URL || window.env.REACT_APP_SERVER_URL;
  let info_url = `${BACKEND_URL}/v1/billing/info`
  let account_url = `${BACKEND_URL}/v1/auth/account`
  let member_url = `${BACKEND_URL}/v1/auth/member`

  const user = {
    id: urlParams.get('id'),
    username: urlParams.get('username'),
    email: urlParams.get('email'),
    is_active: urlParams.get('is_active'),
    created: urlParams.get('created'),
    update: urlParams.get('updated'),
  }


  const saveInfo = () => new Promise((resolve, reject) => {
    store.dispatch(
      authSlice.actions.setAuthTokens({
        token: urlParams.get('token'),
        refreshToken: urlParams.get('refresh'),
        api_token: urlParams.get('api_token'),
      })
    );
    store.dispatch(
      authSlice.actions.setAccount(user)
    );
    resolve();
  })


  const saveAuthData = async () => {
    
    let n = 0 
    while (!store.getState().auth.token && n < 2) {
      console.log(`current auth -> ${JSON.stringify(store.getState().auth)}`)
      await saveInfo();
      await wait(1000);
      n++
    }

    // get account
    await axios.get(account_url)
      .then((r) => {
        store.dispatch(teamSlice.actions.setTeam(r.data))
      })
      .catch((e) => {
        console.log(e)
      })

    // get member info
    await axios.get(member_url)
      .then((r) => {
        store.dispatch(memberSlice.actions.setMember(r.data))
      })
      .catch((e) => {
        console.log(e)
      })

    // getting billing info
    await axios.post(info_url)
      .then((r) => {
        store.dispatch(billingSlice.actions.setBilling(r.data));
      }).catch((err) => {
        console.log(err);
      });

    // redirect to next or onboarding
    if (next){
      window.location.href = next
      localStorage.removeItem('next')
    } else{
      window.location.href = '/account/new';
    }
     
  }


  useEffect(() => {

    saveAuthData();

    },[urlParams, user])


  return (
    <div className='flex h-screen bg-gray-100 dark:bg-gray-900'>
      <Loader item={'Auth'}/>
    </div>
  )
    
}
    
export default GoogleConfirm;