import React, { useState } from 'react'
import { Label, Input, Button } from '@windmill/react-ui'
import axios from "axios";
import Spinner from '../components/Buttons/Spinner';




function ForgotPassword() {

  const BACKEND_URL = process.env.REACT_APP_SERVER_URL || window.env.REACT_APP_SERVER_URL;
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState('none');
  const [fail, setFail] = useState('none');
  const [form, setForm] = useState('block');
  const [title, setTitle] = useState('block');
  const url = `${BACKEND_URL}/v1/auth/get-reset-link`
  const payload = {'email': email}
  

  const handleSubmit = (evt) => {
      evt.preventDefault();
      setIsLoading(true);
      setFail('none');
      axios.post(`${url}`, payload, { skipAuthRefresh: true })
      .then((res) => {
        setForm('none');
        setTitle('none');
        setSuccess('block');
      })
      .catch((err) => {
        setIsLoading(false)
        setFail('block')
      });
    }


  return (
    <div className="flex items-center min-h-screen p-6 bg-gray-100 dark:bg-gray-900">
      <div className="flex-1 h-full max-w-md mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
        <div className="flex flex-col overflow-y-auto">
          <main className="flex items-center justify-center p-6 sm:p-12">
            <div className="w-full">
              <h1 id='title' style={{display: title,  }} className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                Forgot password
              </h1>

              <form onSubmit={handleSubmit} id='email-form' style={{display: form,  }}>

                <Label>
                  <Input className="rounded-md p-2 mt-1" 
                    type="email"
                    placeholder="jane@example.com" 
                    value={email}
                    onChange={e => setEmail(e.target.value)}/>
                </Label>

                <Button type="submit" block className="mt-4">
                  <Spinner display={isLoading}/>
                  Send me a link
                </Button>

              </form>
              <h3 id='success-message' style={{display: success }} className="mb-4 text-l font-semibold text-gray-700 dark:text-gray-200">
                Check you email for a reset-link (might be in spam... <span role='img' aria-label='grimace'>😬</span>)
              </h3>
              <h5 id='fail-message' style={{display: fail }} className="mb-4 text font-semibold text-gray-700 dark:text-gray-200">
                <br></br>
                Opps, we can't find an account matching that email...
              </h5>
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
